
import { Suspense } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../core/store'

const Loading = () => <div>Loading...</div>

export const NoLoggedRoutes = () => {
  const { logged } = useSelector((state: RootState) => state.login)

  // Redirect to home if already authenticated
  if (logged) {
    return <Navigate to="/home/dashboard" replace />
  }

  return (
    <Suspense fallback={<Loading />}>
      <Outlet />
    </Suspense>
  )
}
