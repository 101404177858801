import { Suspense } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../core/store'

const Loading = () => <div>Loading...</div>

export const LoggedRoutes = () => {
  const { loginData } = useSelector((state: RootState) => state.login)

  // Redirect to login if not authenticated
  if (!loginData.logged) {
    return <Navigate to="/login" replace />
  }

  return (
    <Suspense fallback={<Loading />}>
      <Outlet />
    </Suspense>
  )
}
