import { lazy, Suspense, useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { GlobalStyles } from './styles/global'
import Helmet from 'react-helmet'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import icon from './assets/img/icon.png'
import EventBus from 'utils/EventBus'
import { _changeLogin } from 'pages/login/slices/login.slice'
import { NoLoggedRoutes } from 'routes/NoLoggedRoutes'
import { LoggedRoutes } from 'routes/LoggedRoutes'
import ProfileControl from './pages/profileControl/ProfileControl'
import Registration from './pages/registration/Registration'
import Dashboard from './pages/dashboard/Dashboard'
import UserForm from './pages/userForm/UserForm'
import ProfileForm from './pages/profileForm/ProfileForm'
import CustomerControl from './pages/customerControl/CustomerControl'
import Company from './pages/company/Company'
import Faq from './pages/company/faq/Faq'
import EditContent from 'pages/company/whoWeAre/EditContent'
import ViewContent from 'pages/company/whoWeAre/ViewContent'
import Portfolio from 'pages/company/portfolio/Portfolio'
import Products from 'pages/company/portfolio/components/Products'
import FormCoil from 'pages/company/portfolio/components/FormCoil'
import Alerts from 'pages/alerts/Alerts'
import ManagementControl from 'pages/managementControl/managementControl'
import Management from 'pages/managementControl/components/Management'
// import FormManagementControl from 'pages/managementControl/components/FormManagementControl'
import Report from 'pages/reportControl/Report'
// import ReportBilling from 'pages/reportControl/ReportBilling'
import ReportMonthly from 'pages/reportControl/reportMonthly/ReportMonthly'
import ReportVolume from 'pages/reportControl/reportVolume/ReportVolume'
import ReportConsolidated from 'pages/reportControl/reportConsolidated/ReportConsolidated'
import ReportRealizedDay from 'pages/reportControl/reportRealizedDay/ReportRealizedDay'
import AppClientList from 'pages/managementControl/components/AppClientList'
import ReportRH from 'pages/reportControl/reportRH/ReportRH'
import ReportRevenues from 'pages/reportControl/reportRevenues/ReportRevenues'
import FormChapas from 'pages/company/portfolio/components/FormChapas'
import FormProductionGoals from 'pages/managementControl/productionGoals/FormProductionGoals'
// import ProductionGoals from 'pages/managementControl/productionGoals/ProductionGoals'
import ProvisionManagementControl from 'pages/managementControl/ProvisionManagementControl'
import FT from 'pages/ft/FT'
import ReportBudget from 'pages/reportControl/reportBudget/ReportBudget'
import ReportBudgetConfig from 'pages/reportControl/reportBudgetConfig/BudgetConfig'
import FormEditCoil from 'pages/company/portfolio/components/FormEditCoil'
import FormEditChapas from 'pages/company/portfolio/components/FormEditChapas'
import FormCustomerControl from 'pages/customerControl/FormCustomerControl'
import CustomerControlDocumentation from 'pages/customerControl/CustomerControlDocumentation'
import ProvisionUpdateManagementControl from 'pages/managementControl/ProvisionUpdateManagementControl'
import ReportDashboard from 'pages/reportControl/ReportDashboard/ReportDashboard'
// import ReportProductionGoals from 'pages/reportControl/reportProductionGoals/ReportProductionGoals'
// import Purchases from 'pages/purchases/Purchases'
// import Projects from 'pages/projects/Projects'
// import Chat from 'pages/chat/Chat'
import FormCaixas from 'pages/company/portfolio/components/FormCaixas'
import SalesGoals from 'pages/managementControl/salesGoals/SalesGoals'
import FormSalesGoals from 'pages/managementControl/salesGoals/FormSalesGoals'
// import ProposalGoals from 'pages/managementControl/proposalGoals/ProposalGoals'
// import FormProposalGoals from 'pages/managementControl/proposalGoals/FormProposalGoals'
import MaterialTaxes from 'pages/managementControl/materialTaxes/MaterialTaxes'
import MaterialForm from 'pages/managementControl/materialTaxes/MaterialForm'
import CustomerReview from 'pages/managementControl/customerReview/CustomerReview'
import { ReportSeller } from 'pages/reportControl/reportSeller/ReportSeller'
import DeliveryMapModules from 'pages/reportControl/deliveryMap/DeliveryMapModules'
import PurchaseRequests from 'pages/purchaseRequests/PurchaseRequests'
import BudgetTools from 'pages/purchaseRequests/purchaseOrder/BudgetTools'
import BookingForm from 'pages/purchaseRequests/purchaseOrder/BookingForm'
import PurchaseOrderPage from 'pages/purchaseOrder/PurchaseOrder'
import { ReportProposalForDirection } from 'pages/reportControl/proposalForDirection/ReportProposalForDirection'
import RequestForProposals from 'pages/reports/requestForProposals/index'
import AuditFT from 'pages/ft/sampleDevFT/stepsBoxPlate/AuditFT'
import Notifications from 'pages/notification/Notifications'
import ViewOrderMap from 'pages/reportControl/deliveryMap/components/ViewOrderMap'
import { SalesReport } from 'pages/reportControl/salesReport/SalesReport'
import { Stock } from 'pages/reportControl/stock/Stock'
import OrdersProduction from 'pages/ordersProduction/OrdersProduction'
import Solicitation from 'pages/solicitation/Solicitation'
import { ListProjectsAndItems } from 'pages/purchaseRequests/ListProjectsAndItems'
import { PreFTPortfolio } from 'pages/ft/PreFTPortfolio'
import { PreFTPortfolioCoil } from 'pages/ft/PreFTPortfolioCoil'
import { PreFTPortfolioPlate } from 'pages/ft/PreFTPortfolioPlate'
import { PreFTPortfolioBox } from 'pages/ft/PreFTPortfolioBox'
import { Estoque } from 'pages/managementControl/estoque/Estoque'
import { CalculatorPage } from 'pages/home/calculator/CalculatorPage'
import ProductControl from 'pages/productControl/ProductControl'
import StockCreate from 'pages/productControl/StockCreate'
import Tagging from 'pages/productControl/Tagging'
import StockEdit from 'pages/productControl/StockEdit'
import StockList from 'pages/productControl/StockList'
import StockView from 'pages/productControl/StockView'
import StockProductView from 'pages/productControl/stockProductView'
import TaggingQueue from 'pages/productControl/TaggingQueue'

// Lazy load main layout components
const LoginPage = lazy(async () => import('./pages/login/LoginPage'))
const HomePage = lazy(async () => import('./pages/home/HomePage'))
const RecoverPage = lazy(async () => import('./pages/recover/RecoverPage'))
const NotFound = lazy(async () => import('./pages/notFound/NotFound'))
const PrivacyPage = lazy(async () => import('./pages/privacy/PrivacyPage'))
const QueryPage = lazy(async () => import('./pages/user/QueryPage'))

// Loading component for suspense fallback
const Loading = () => <div>Loading...</div>

const loggedRoutes = (
  <Route path="/" element={<LoggedRoutes />}>
    <Route path="home" element={
      <Suspense fallback={<Loading />}>
        <HomePage />
      </Suspense>
    }>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="notifications" element={<Notifications />} />
        <Route path="calculator" element={<CalculatorPage />} />
        <Route path="profile-control" element={<ProfileControl />} />
        <Route path="registration" element={<Registration />} />
        <Route path="user-form" element={<UserForm />} />
        <Route path="profile-form" element={<ProfileForm />} />
        <Route path="customer-control" element={<CustomerControl />} />
        <Route path="customer-control/form" element={<FormCustomerControl />} />
        <Route
          path="customer-control/documentation"
          element={<CustomerControlDocumentation />}
        />
        <Route path="purchase-order" element={<PurchaseOrderPage />} />
        <Route path="company" element={<Company />} />
        <Route path="faq" element={<Faq />} />
        <Route path="edit-content-company" element={<EditContent />} />
        <Route path="view-content-company" element={<ViewContent />} />
        <Route path="portfolio/:value" element={<Portfolio />} />
        <Route path="portfolio" element={<Portfolio />} />
        <Route path="portfolio/products" element={<Products />} />
        <Route
          path="portfolio/products/formCoil/:id"
          element={<FormEditCoil />}
        />
        <Route path="portfolio/products/formCoil" element={<FormCoil />} />
        <Route
          path="portfolio/products/formChapas/:id"
          element={<FormEditChapas />}
        />
        <Route path="portfolio/products/formChapas" element={<FormChapas />} />
        <Route path="portfolio/products/formCaixas" element={<FormCaixas />} />
        <Route path="alerts" element={<Alerts />} />
        <Route path="managementControl" element={<ManagementControl />} />

        <Route path="managementControl/estoque" element={<Estoque />} />

        <Route path="managementControl/management" element={<Management />} />
        <Route
          path="managementControl/management/management-control-form-edit/:id"
          element={<ProvisionUpdateManagementControl />}
        />
        <Route
          path="managementControl/management/management-control-form"
          element={<ProvisionManagementControl />}
        />
        <Route
          path="managementControl/management/link-app"
          element={<AppClientList />}
        />
        <Route
          path="managementControl/management/customer-review"
          element={<CustomerReview />}
        />
        <Route
          path="managementControl/management/sales-goals-form"
          element={<FormSalesGoals />}
        />
        <Route
          path="managementControl/management/sales-goals"
          element={<SalesGoals />}
        />
        <Route
          path="managementControl/production-goals-form"
          element={<FormProductionGoals />}
        />
        {/* <Route path='managementControl/production-goals' element={<ProductionGoals />} /> */}
        {/* <Route path='managementControl/management/proposal-goals' element={<ProposalGoals />} />
      <Route path='managementControl/management/proposal-goals-form' element={<FormProposalGoals />} /> */}
        <Route
          path="managementControl/management/material-taxes"
          element={<MaterialTaxes />}
        />
        <Route
          path="managementControl/management/material-taxes-form"
          element={<MaterialForm />}
        />
        <Route path="report" element={<Report />} />
        <Route path="report/dashboard" element={<ReportDashboard />} />
        <Route path="report/billing/monthly" element={<ReportMonthly />} />
        <Route path="report/volume" element={<ReportVolume />} />
        <Route path="report/consolidated" element={<ReportConsolidated />} />
        <Route path="report/realizedDay" element={<ReportRealizedDay />} />
        {/* <Route path='report/productionGoals' element={<ReportProductionGoals />} /> */}
        <Route path="managementControl/rh" element={<ReportRH />} />
        <Route path="report/revenues" element={<ReportRevenues />} />
        <Route path="report/reportBudget" element={<ReportBudget />} />
        <Route path="report/reportBudgetConfig" element={<ReportBudgetConfig />} />
        <Route path="report/reportSeller" element={<ReportSeller />} />
        <Route path="report/deliveryMap" element={<DeliveryMapModules />} />
        <Route
          path="report/proposalsForDirection"
          element={<ReportProposalForDirection />}
        />
        <Route path="report/sales-report" element={<SalesReport />} />
        <Route path="report/stock" element={<Stock />} />
        <Route
          path="report/requestForProposals"
          element={<RequestForProposals />}
        />
        <Route path="ft" element={<FT />} />
        <Route path="ft/audit" element={<AuditFT />} />
        <Route path="ft/portfolio" element={<PreFTPortfolio />} />
        <Route path="ft/portfolio/coil" element={<PreFTPortfolioCoil />} />
        <Route path="ft/portfolio/plate" element={<PreFTPortfolioPlate />} />
        <Route path="ft/portfolio/box" element={<PreFTPortfolioBox />} />
        <Route path="purchaseRequests" element={<PurchaseRequests />} />
        <Route path="purchaseRequests/budgetTools" element={<BudgetTools />} />
        <Route path="purchaseRequests/bookingForm" element={<BookingForm />} />
        <Route
          path="purchaseRequests/listProjectsAndItems"
          element={<ListProjectsAndItems />}
        />
        <Route path="deliveryMap/orders" element={<ViewOrderMap />} />
        <Route path="ordersProduction" element={<OrdersProduction />} />
        {/* <Route path='purchases' element={<Purchases />} /> */}
        {/* <Route path='projects' element={<Projects />} /> */}
        {/* <Route path='chat' element={<Chat />} /> */}
        <Route path="solicitations" element={<Solicitation />} />
        <Route path="product-control" element={<ProductControl />} />
        <Route path="stock-create" element={<StockCreate />} />
        <Route path="stock-List" element={<StockList />} />
        <Route path="stock-view/:id" element={<StockView />} />
        <Route path="stock-edit/:id" element={<StockEdit />} />
        <Route path="stock-product-view/:id" element={<StockProductView />} />
        <Route path="tagging" element={<Tagging />} />
        <Route path="tagging-queue" element={<TaggingQueue />} />

    </Route>
    <Route path="privacy-policy" element={<PrivacyPage />} />
    <Route path="*" element={<NotFound />} />
  </Route>
)

const noLoggedRoutes = (
  <Route path="/" element={<NoLoggedRoutes />}>
    <Route index element={
      <Suspense fallback={<Loading />}>
        <LoginPage />
      </Suspense>
    } />
    <Route path="login" element={
      <Suspense fallback={<Loading />}>
        <LoginPage />
      </Suspense>
    } />
    <Route path="recover" element={
      <Suspense fallback={<Loading />}>
        <RecoverPage />
      </Suspense>
    } />
    <Route path="query" element={
      <Suspense fallback={<Loading />}>
        <QueryPage />
      </Suspense>
    } />
    <Route path="*" element={
      <Suspense fallback={<Loading />}>
        <NotFound />
      </Suspense>
    } />
  </Route>
)

function App() {
  const dispatch = useDispatch()

  const logout = () => {
    toast.warning('Sessão expirada, logue novamente', { toastId: 'warning-logout' })
    dispatch(
      _changeLogin({
        tokenData: '',
        accessToken: '',
        logged: false,
        email: '',
        picture: ''
      })
    )
  }

  useEffect(() => {
    const logoutHandler = () => logout()
    EventBus.on('logout', logoutHandler)

    // Cleanup subscription on unmount
    return () => {
      EventBus.remove('logout', logoutHandler)
    }
  }, [])

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="privacy-policy" element={
            <Suspense fallback={<Loading />}>
              <PrivacyPage />
            </Suspense>
          } />
          {loggedRoutes}
          {noLoggedRoutes}
        </Routes>
      </BrowserRouter>

      <GlobalStyles />

      <Helmet>
        <link rel="apple-touch-icon" sizes="180x180" href={icon} />
        <link rel="icon" type="image/png" sizes="32x32" href={icon} />
        <link rel="icon" type="image/png" sizes="16x16" href={icon} />
        <link rel="mask-icon" href={icon} color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
        <script src="https://momentjs.com/downloads/moment-with-locales.min.js"></script>
      </Helmet>
      <ToastContainer />
    </>
  )
}
export default App
