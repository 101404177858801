/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Box, Grid, TextField, Button, IconButton } from '@mui/material'
import TabsUnstyled from '@mui/base/TabsUnstyled'
import BreadcrumbsComponent from 'components/Breadcrumbs/BreadcrumbsComponent'
import {
  DivModules,
  TabsList,
  Tab,
  TabPanel
} from './styles/deliveryMap.styles'
import SearchIcon from '@mui/icons-material/Search'
import { DateRangePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { ptBR } from 'date-fns/locale'
import Brief from './brief/Brief'

const DeliveryMapModules: React.FC = () => {
  // Initialize stableDateRange to today's date
  const today = new Date()
  const [selectDateRange, setSelectDateRange] = useState<[Date | null, Date | null]>([null, null])
  const [stableDateRange, setStableDateRange] = useState<[Date | null, Date | null]>([today, today])
  const [isDateValid, setIsDateValid] = useState(false)
  const [isApplied, setIsApplied] = useState(false)

  const isValidDate = (date: Date | null) => date instanceof Date && !isNaN(date.getTime())

  const handleApplyClick = () => {
    if (selectDateRange[0] && selectDateRange[1]) {
      setStableDateRange(selectDateRange)
    }
  }

  const startDate = stableDateRange[0]
  const endDate = stableDateRange[1]

  useEffect(() => {
    setIsDateValid(isValidDate(startDate) && isValidDate(endDate))
  }, [startDate, endDate])

  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <BreadcrumbsComponent
          breadcrumbs={[
            { text: 'Relatórios', url: '/home/report' },
            { text: 'Mapa de entrega' }
          ]}
        />
      </Box>
      <div>
        <strong>Mapa de entrega</strong>
      </div>

      <Grid container spacing={3} mt={0}>
        <Grid item xs={12}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: '5px',
              marginRight: '0px'
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <Grid container spacing={3} mt={0}>
                <Grid item xs={12}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      marginTop: '10px',
                      marginRight: '0px'
                    }}
                  >
                    <DateRangePicker
                      startText="Data Inicial"
                      endText="Data Final"
                      inputFormat="dd/MM/yyyy"
                      value={selectDateRange}
                      onChange={(newValue) => {
                        setSelectDateRange(newValue)
                      }}
                      renderInput={(startProps, endProps) => (
                        <Box display="flex" gap={2}>
                          <TextField {...startProps} size="small" helperText={null} />
                          <span>até</span>
                          <TextField {...endProps} size="small" helperText={null} />
                        </Box>
                      )}
                    />
                    <IconButton
                      color="primary"
                      onClick={handleApplyClick}
                      style={{ marginLeft: '10px' }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </div>
                </Grid>
              </Grid>
            </LocalizationProvider>
          </div>
        </Grid>
      </Grid>

      <DivModules>
        <TabsUnstyled defaultValue={0}>
          <Box sx={{ width: '100%', overflowX: 'auto' }}>
            <TabsList>
              {['RESUMO'].map((item) => (
                <Tab key={item}>{item}</Tab>
              ))}
            </TabsList>
          </Box>
          <TabPanel value={0}>
            {isDateValid && (
              <Brief
                date={{ startDate: stableDateRange[0] ?? undefined, endDate: stableDateRange[1] ?? undefined }}
              />
            )}
          </TabPanel>
        </TabsUnstyled>
      </DivModules>
    </>
  )
}

export default DeliveryMapModules
